import moment from 'moment'
import 'moment/locale/ru'
import { BigNumber } from 'bignumber.js'
import { ErrorResponse } from './types'
import { ChangeEvent } from 'react'

export const formatDateTime = (date: string | number | Date | null) => {
  if (!date) return '-'
  // return moment(new Date(date)).format('DD-MMM-YYYY HH:mm:ss').toUpperCase()
  return moment.utc(date).format('DD-MM-YYYY HH:mm:ss').toUpperCase()
  // return moment(new Date(date)).format()
}

export const formatDate = (date: string | number | Date) => {
  if (!date) return '-'
  return moment(new Date(date)).format('DD-MM-YYYY')
  // return moment(new Date(date)).format()
}

export const formatDateWithDash = (date?: string | number | Date) => {
  if (!date) return '-'
  return moment(new Date(date)).format('DD-MM-YYYY')
}

export const formatDateWithDots = (date?: string | number | Date) => {
  if (!date) return '-'
  return moment(new Date(date)).format('DD.MM.YYYY')
}

export const formatAPIDate = (date: string | number | Date) => {
  if (!date) return '-'
  // return moment(new Date(date)).format('YYYY-MM-DDT00:00:00')
  return moment(new Date(date)).format('YYYY-MM-DD')
}

export const formatAPIDateTime = (date: string | number | Date) => {
  if (!date) return '-'
  return `${moment(new Date(date)).format('YYYY-MM-DDTHH:mm:00')}.000Z`
}

//created: 06-SEP-2022 05:21:36   updated: 06-SEP-2022 06:00:32
export const formatUIDate = (date: string | number | Date) => {
  if (!date) return '-'
  // return moment(new Date(date)).format('DD-MMM-YYYY').toUpperCase()
  return moment(new Date(date)).format('DD-MM-YYYY')
}

export const formatCalculateAge = (date: string | number | Date) => {
  const age = new Date().getFullYear() - new Date(date).getFullYear()
  if (!date) return '-'
  return new Date().setFullYear(1970) < new Date(date).setFullYear(1970)
    ? age - 1
    : age
}

export const formatAge = (date: Date) => {
  const inputDate = new Date(date)
  const currentDate = new Date()

  const timeDifference = currentDate.getTime() - inputDate.getTime()

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)

  return `${days} дней, ${String(hours).padStart(2, '0')}:${String(
    minutes
  ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}

const getFormattingNumberLocale = () => {
  //default
  const res = {
    decimalSeparator: '.',
    groupSeparator: ','
  }

  // convert a number formatted according to locale
  const str = parseFloat('1234.56').toLocaleString()

  // if the resulting number does not contain previous number
  // (i.e. in some Arabic formats), return defaults
  if (!str.match('1')) return res

  // get decimal and thousand separators
  res.decimalSeparator = str.replace(/.*4(.*)5.*/, '$1')
  res.groupSeparator = str.replace(/.*1(.*)2.*/, '$1')

  // return results
  return res
}

export const setFormattingNumberOptions = (options: {
  decimalSeparator: string
  groupSeparator: string
}) => {
  localStorage.setItem('formatting-numbers', JSON.stringify(options))
}

export const clearFormattingNumberOptions = () => {
  localStorage.removeItem('formatting-numbers')
}

export const getFormattingNumberOptions = () => {
  const options = localStorage.getItem('formatting-numbers')
  if (options) return JSON.parse(options)
  return getFormattingNumberLocale()
}

export const formatNumber = (
  x: BigNumber.Value,
  decimalsCount = 2,
  decimalSeparator = '.',
  groupSeparator = ','
) => {
  const fmt = {
    decimalSeparator, //,
    groupSeparator, //.
    //prefix // =>
    groupSize: 3
    //secondaryGroupSize 2 results to: => 12.34.56.789,124
  }
  return new BigNumber(x).toFormat(decimalsCount, fmt)
}

export const formatAmount = (
  amount: number | null | undefined,
  decimals: undefined,
  dashEmpty?: boolean | undefined
  // currency = 'RUB'
) => {
  if (dashEmpty && amount === 0) return '-'
  if (amount === null || amount === undefined) return '-'
  if (isNaN(amount)) return '-'
  /*if (currency) {
    return amount.toLocaleString(undefined, { style: 'currency', currency });
  }*/
  const options = getFormattingNumberOptions()
  return formatNumber(
    amount,
    decimals || 2,
    options.decimalSeparator,
    options.groupSeparator
  )
}

export const autoResizeInput = (event: any) => {
  event.target.style.height = '40px'
  event.target.style.height = `${event.target.scrollHeight + 2}px`
}

/**
 * @param o Original object
 * @param keys Array of keys to remove
 * @returns Copy of the object without the disallowed properties or empty object
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const omit = <T extends {}>(o: T | undefined, keys: Array<keyof T>) =>
  typeof o === 'object' && o != null && !Array.isArray(o)
    ? Object.fromEntries(
        Object.entries(o).filter(([key]) => !keys.some((k) => k === key))
      )
    : {}

export const omitBy = <T extends Record<string, unknown>>(
  o: T,
  ...predicates: Array<(value: T[keyof T], key: keyof T) => boolean>
): Partial<T> => {
  const result: Partial<T> = {}

  for (const key in o) {
    const value = o[key]
    const shouldOmit = predicates.some((predicate) => predicate(value, key))

    if (!shouldOmit) {
      result[key] = value
    }
  }

  return result
}

export const mapBooleanStringToBoolean = (
  s: 'true' | 'false' | string | undefined | null | boolean,
  defaultValue = undefined
) => {
  if (typeof s === 'boolean') return s

  switch (s) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return defaultValue
  }
}

export const utcToLocal = (date?: string): Date =>
  typeof date === 'string'
    ? new Date(
        new Date(date).getTime() -
          new Date(date).getTimezoneOffset() * 60 * 1000
      )
    : new Date()

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {}

export const handleErrors = (error: unknown) => {
  const { data } = error as ErrorResponse
  if (Array.isArray(data.detail))
    return data?.detail
      .map((obj) => {
        const nameError = obj.loc[1]
        const msgError = obj.msg

        return `${nameError}: ${msgError}`
      })
      .join('; ')
  return data?.detail
}

export const handleMessageError = (error: unknown, innMessage: string) => {
  const { data, status } = error as ErrorResponse

  if (status === 409) {
    return innMessage
  }
  if (Array.isArray(data?.detail)) return data.detail[0]?.msg
}

export const handlePasportChange = (
  e: ChangeEvent<HTMLInputElement>,
  setFieldValue: (arg0: string, arg1: string) => void
) => {
  const value = e.target.value.replace(/\D/g, '')
  let formattedValue = ''

  if (value.length > 4) {
    formattedValue = `${value.slice(0, 4)} ${value.slice(4, 10)}`
  } else {
    formattedValue = value
  }

  setFieldValue('pasport', formattedValue)
}
