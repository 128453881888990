import { useTranslation } from 'react-i18next'
import { Table } from 'reactstrap'
import model from '../../../model'
import { useEffect, useState } from 'react'
import cx from 'classnames'
import { toast, ToastContainer } from 'react-toastify'

type FieldsDescriptionType = {
  [key: string]: {
    label?: string
    isStrong?: boolean // сделать шрифт поля bold
    isCopy?: boolean // поле с кнопкой копирования значения поля
    isDateTime?: boolean
    isDate?: boolean
    isAmount?: boolean
    isItem?: boolean // подпункты, используется для list-type: disc
    fieldName?: string
    isEmpty?: boolean
    isPercent?: boolean
    isDefaultOpen?: boolean // открытый аккардион по умолчанию
    isAccordion?: {
      [key: string]: FieldsDescriptionType
    }
    mappingValue?: () => string
    dashEmpty: boolean // для isAmount === 0 выводит '-'
  }
}

type LoanTable = {
  className?: string
  title?: string
  fieldsDescription: FieldsDescriptionType
  entity: any
  style: any
}

const LoanTable = ({
  className,
  title,
  fieldsDescription,
  entity,
  style
}: LoanTable) => {
  const { t } = useTranslation()
  const [showFields, setShowFields] = useState<string[]>([])

  useEffect(() => {
    const startShow: string[] = []
    Object.entries(fieldsDescription)?.map(([field, value]: any) => {
      if (value.isAccordion && value.isDefaultOpen) {
        startShow.push(field)
      }
    })
    setShowFields(startShow)
  }, [fieldsDescription])

  const keyToText = (fieldsDescription: any, fieldKey: any) => {
    const descr = fieldsDescription[fieldKey] || {}

    const unit = descr.unit ? `, ${t(descr.unit)}` : ''
    if (descr.label) return t(descr.label) + unit
    if (descr.fieldName) return t(descr.fieldName) + unit
    return t(fieldKey) + unit
  }

  const valueToText = (fieldsDescription: any, fieldKey: any) => {
    const value = model.fieldValueToText(fieldKey, entity, fieldsDescription)
    if (fieldsDescription[fieldKey]) return value

    return t(value)
  }

  const toggle = (field: string) => {
    const newShow: string[] = [...showFields]
    if (newShow.length === 0) {
      newShow.push(field)
    } else {
      const item = newShow.find((el) => el == field)
      if (item !== field) {
        newShow.push(field)
      } else
        newShow.forEach((el, index) => {
          if (el == item) {
            newShow.splice(index, 1)
          }
        })
    }
    setShowFields([...newShow])
  }

  return (
    <div className='mt-3' style={{ minWidth: '320px', ...style }}>
      <ToastContainer autoClose={500} limit={1} />

      {title ? <h5 className='text-bold mb-3'>{title}</h5> : null}

      <div className='table-responsive'>
        <Table className={className || 'table-sm align-middle mb-0'}>
          <tbody>
            {Object.entries(fieldsDescription).map(([field, value]: any) => {
              if (value.isAccordion) {
                return (
                  <>
                    <tr key={field} onClick={() => toggle(field)}>
                      <td
                        className={cx('px-0', {
                          'text-strong': value.isStrong
                        })}
                      >
                        {keyToText(fieldsDescription, field)}
                      </td>
                      <td className='d-flex gap-2'>
                        <span>{valueToText(fieldsDescription, field)}</span>
                        {showFields.find((el) => el == field) ? (
                          <i className='mdi mdi-chevron-up' />
                        ) : (
                          <i className='mdi mdi-chevron-down' />
                        )}
                      </td>
                    </tr>
                    {showFields.find((el) => el == field)
                      ? Object.entries(value.isAccordion).map(
                          ([fieldItem, valueItem]: any) => {
                            return (
                              <tr key={fieldItem}>
                                {valueItem.isItem ? (
                                  <>
                                    <td>
                                      <ul className='m-0 px-3'>
                                        <li>
                                          {keyToText(
                                            value.isAccordion,
                                            fieldItem
                                          )}
                                        </li>
                                      </ul>
                                    </td>
                                    <td>
                                      {valueToText(
                                        value.isAccordion,
                                        fieldItem
                                      )}
                                      {valueItem.isCopy ? (
                                        <i
                                          className='ri-file-copy-line ps-1 link-success'
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            navigator?.clipboard?.writeText(
                                              entity[fieldItem]
                                            )
                                            toast('Copied', {
                                              position: 'top-center',
                                              hideProgressBar: true
                                            })
                                          }}
                                        />
                                      ) : null}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className='p-0'>
                                      {keyToText(value.isAccordion, fieldItem)}
                                    </td>
                                    <td>
                                      {valueToText(
                                        value.isAccordion,
                                        fieldItem
                                      )}
                                      {valueItem.isCopy ? (
                                        <i
                                          className='ri-file-copy-line ps-1 link-success'
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            navigator?.clipboard?.writeText(
                                              entity[fieldItem]
                                            )
                                            toast('Copied', {
                                              position: 'top-center',
                                              hideProgressBar: true
                                            })
                                          }}
                                        />
                                      ) : null}
                                    </td>
                                  </>
                                )}
                              </tr>
                            )
                          }
                        )
                      : null}
                  </>
                )
              }
              return (
                <tr key={field}>
                  <td className={cx('px-0', { 'text-strong': value.isStrong })}>
                    {keyToText(fieldsDescription, field)}
                  </td>
                  <td>
                    {valueToText(fieldsDescription, field)}
                    {value.isCopy ? (
                      <i
                        className='ri-file-copy-line ps-1 link-success'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigator?.clipboard?.writeText(entity[field])
                          toast('Copied', {
                            position: 'top-center',
                            hideProgressBar: true
                          })
                        }}
                      />
                    ) : null}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default LoanTable
