import { useState, useEffect, useMemo } from 'react'
import { Row, Col, Input, FormGroup } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import Widget from './LoanWidget'
import Client from '../../../client'
import {
  useGetLoanCardQuery,
  useGetExternalServicesQuery
} from 'src/services/loans'
import { ConfirmButton } from '../ConfirmButton'
import {
  useDownloadPaymentScheduleMutation,
  useSetReloadContractMutation
} from 'src/services/payments'
import moment from 'moment'
import LoanTable from 'src/Components/Common/EntitiesView/LoanTable'
import {
  fieldsDescriptionDetailsOne,
  fieldsDescriptionDetailsTwo,
  fieldsDescriptionDetailsThree,
  fieldsDescriptionMainLoans,
  fieldsDescriptionRepayments,
  fieldsDescriptionDebt,
  fieldsDescriptionOverdueDebts,
  fieldsDescriptionOverdueDebtsExtension,
  fieldsDescriptionOverdueDebtsFreezing,
  fieldsDescriptionOverdueDebtsPromotion,
  fieldsDescriptionOverdueDebtsSale
} from 'src/pages/Admin/Loans/table'

const generateOperationsData = (entity, operationTypes, categories) => {
  const initialResult = categories.reduce((acc, category) => {
    operationTypes.forEach((type) => {
      acc[`amount_${type.toLowerCase()}_${category}`] = 0
    })
    return acc
  }, {})

  const result = { ...initialResult }

  categories.forEach((category) => {
    entity?.operations?.[category]?.forEach((value) => {
      const key = `amount_${value.operation_type.toLowerCase()}_${category}`

      if (result[key] !== undefined) {
        result[key] = value.amount
      }
    })
  })

  return result
}

const EntityContent = ({ entity }) => {
  const { data: loan_issue_card } = useGetLoanCardQuery(entity?.id, {
    skip: entity?.id == null
  })

  const isInstallment = useMemo(
    () => entity?.product_copy?.product_code === 'IL',
    [entity?.product_copy?.product_code]
  )

  const { data: externalServicesData } = useGetExternalServicesQuery(
    entity?.id,
    {
      skip: entity?.id == null
    }
  )

  const { t } = useTranslation()
  const [detailsUrl, setDetailsUrl] = useState('')
  const [dropPA, setDropPA] = useState(false)
  const [dropInsurance, setDropInsurance] = useState(false)
  const [dropJuridical, setDropJuridical] = useState(false)
  const [dropTelemedicine, setDropTelemedicine] = useState(false)
  const [contractStatus, setContractStatus] = useState('')
  const [contractError, setContractError] = useState('')
  const [contractLoading, setContractLoading] = useState(false)
  const [operationsList, setOperationsList] = useState({})
  const [externalServicesAmount, setExternalServicesAmount] = useState({})
  const [externalServicesLoading, setExternalServicesLoading] = useState(false)
  const [externalServicesId, setExternalServicesId] = useState({})
  const [externalServicesRelaod, setExternalServicesRelaod] = useState([])

  const [setReloadContract] = useSetReloadContractMutation()
  const [downloadSchedule, { data: scheduleFileUrl }] =
    useDownloadPaymentScheduleMutation()

  const timeClient = entity?.timezone
    ? moment()
        .utcOffset(Number(entity?.timezone?.slice(4)) * 60)
        .format('DD-MM-YYYY')
    : '-'

  useEffect(() => {
    if (externalServicesData?.length) {
      const externalServicesIdList = {
        juridical: '',
        telemedicine: '',
        insurance: ''
      }
      const externalServices = {
        amount_insurance_debt: 0,
        amount_insurance_paid: 0,
        amount_insurance_accrued: 0,
        amount_juridical_debt: 0,
        amount_juridical_paid: 0,
        amount_juridical_accrued: 0,
        amount_telemedicine_debt: 0,
        amount_telemedicine_paid: 0,
        amount_telemedicine_accrued: 0,
        amount_services_debt: 0
      }
      externalServicesData?.forEach((value) => {
        if (value.tag === 'juridical') {
          externalServices.amount_juridical_accrued = value.amountAccrued
          externalServices.amount_juridical_paid = value.amountPaid
          externalServices.amount_juridical_debt =
            value.amountAccrued - value.amountPaid
          externalServicesIdList.juridical = value.id
          if (!value.isRefunded) {
            externalServices.amount_services_debt += value.amountAccrued
            setDropJuridical(true)
          }
        }
        if (value.tag === 'insurance') {
          externalServices.amount_insurance_accrued = value.amountAccrued
          externalServices.amount_insurance_paid = value.amountPaid
          externalServices.amount_insurance_debt =
            value.amountAccrued - value.amountPaid
          externalServicesIdList.insurance = value.id
          if (!value.isRefunded) {
            externalServices.amount_services_debt += value.amountAccrued
            setDropInsurance(true)
          }
        }
        if (value.tag === 'telemedicine') {
          externalServices.amount_telemedicine_accrued = value.amountAccrued
          externalServices.amount_telemedicine_paid = value.amountPaid
          externalServices.amount_telemedicine_debt =
            value.amountAccrued - value.amountPaid
          externalServicesIdList.telemedicine = value.id
          if (!value.isRefunded) {
            externalServices.amount_services_debt += value.amountAccrued
            setDropTelemedicine(true)
          }
        }
      })
      setExternalServicesAmount(externalServices)
      setExternalServicesId(externalServicesIdList)
    } else {
      const externalServices = {
        amount_juridical_debt: 0,
        amount_telemedicine_debt: 0
      }
      setExternalServicesAmount(externalServices)
    }
    setExternalServicesLoading(true)
  }, [entity, externalServicesData?.length])

  useEffect(() => {
    const externalReload = []
    if (entity.externalServices?.length) {
      if (!dropJuridical && externalServicesId.juridical)
        externalReload.push(externalServicesId.juridical)
      if (!dropInsurance && externalServicesId.insurance)
        externalReload.push(externalServicesId.insurance)
      if (!dropTelemedicine && externalServicesId.telemedicine)
        externalReload.push(externalServicesId.telemedicine)
    }
    setExternalServicesRelaod(externalReload)
  }, [
    dropJuridical,
    dropInsurance,
    dropTelemedicine,
    externalServicesId,
    entity
  ])

  useEffect(() => {
    console.log('loan =>', entity)

    return () => {
      if (detailsUrl) URL.revokeObjectURL(detailsUrl)
    }
  }, [detailsUrl, entity, entity.id])

  let ext_details = {}
  if (entity.ext_details) {
    ext_details = {
      extension_available: entity.ext_details.extension_available,
      amount_for_extension: entity.ext_details.amount_for_extension
    }
  }

  const saveContract = () => {
    setContractStatus('')
    setContractError('')
    setContractLoading(true)
    setReloadContract({
      loanId: entity.id,
      body: {
        drop_pa: entity.externalServices?.length ? false : dropPA,
        drop_insurance: entity.externalServices?.length ? false : dropInsurance,
        drop_external_services_ids: externalServicesRelaod
      }
    })
      .then((res) => {
        setContractStatus(t('saved'))
      })
      .catch((err) => {
        setContractError(err.toString())
      })
      .finally(() => setContractLoading(false))
  }

  const downloadDetails = (
    url,
    fileName = `credit-details-${entity?.id}.xlsx`
  ) => {
    console.log('file url =>', url)

    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = fileName
    a.click()
    document.body.removeChild(a)
    console.log('SUCC')
  }

  const download = (loanId) => {
    if (detailsUrl) {
      downloadDetails(detailsUrl)
      return
    }

    switch (entity?.product_copy?.product_code) {
      case 'il':
      case 'IL':
        Client.getILLoanCredit(loanId)
          .then((file) => file.blob())
          .then((blob) => window.URL.createObjectURL(blob))
          .then((url) => {
            setDetailsUrl(url)
            downloadDetails(url)
          })
          .catch((err) => {
            console.log(err)
          })
        break

      case 'pdl':
      case 'PDL':
      default:
        Client.getLoanCredit(loanId)
          .then((file) => file.blob())
          .then((blob) => window.URL.createObjectURL(blob))
          .then((url) => {
            setDetailsUrl(url)
            downloadDetails(url)
          })
          .catch((err) => {
            console.log(err)
          })
        break
    }
  }

  const downloadPaymentschedule = async (loanId) => {
    if (scheduleFileUrl) {
      downloadDetails(scheduleFileUrl, `schedule.csv`)
      return
    }
    const blob = await downloadSchedule(loanId)
    if ('data' in blob) downloadDetails(blob.data, `schedule.csv`)
  }

  useEffect(() => {
    const operationTypes = ['principal', 'interest', 'penalty', 'overpayment']
    const categories = ['accrued', 'debt', 'paid', 'writeoff']

    const tableData = generateOperationsData(entity, operationTypes, categories)

    setOperationsList(tableData)
  }, [entity])

  return (
    <Row>
      <Col xxl='12' xl='12' className='order-2 order-xxl-1'>
        <div className='d-flex gap-4 flex-wrap'>
          <div className='mt-3'>
            <h5 className='text-bold'>{t('Loan details')}</h5>
            <div className='d-flex flex-row flex-wrap gap-3'>
              <LoanTable
                entity={{ ...entity, loan_issue_card }}
                fieldsDescription={fieldsDescriptionDetailsOne(t)}
              />
              <LoanTable
                entity={entity}
                fieldsDescription={fieldsDescriptionDetailsTwo(t)}
                style={{ minWidth: '250px' }}
              />
              <LoanTable
                entity={entity}
                fieldsDescription={fieldsDescriptionDetailsThree(t)}
              />
            </div>

            <div className='d-flex align-items-center gap-3'>
              <div
                className='note text-info text-decoration-underline mt-2 mb-3'
                role='button'
                onClick={() => download(entity?.id)}
              >
                {t('Download loan detail')}
              </div>
              {isInstallment && (
                <div
                  className='note text-info text-decoration-underline mt-2 mb-3'
                  role='button'
                  onClick={() => downloadPaymentschedule(entity?.id)}
                >
                  {t('Download payment schedule')}
                </div>
              )}
            </div>
          </div>

          {!!entity && externalServicesLoading && (
            <Widget
              loan={{ ...entity, ...externalServicesAmount }}
              style={{ marginLeft: -70, marginTop: 20 }}
            />
          )}
        </div>
        <div className='mt-5 mb-3'>
          <h5>{t('Amount services')}</h5>
          <div className='d-flex flex-row align-items-center gap-3 mb-3 p-2'>
            <FormGroup
              switch
              className='d-flex flex-row align-items-center gap-2 form-check-right'
            >
              <div className='mt-1'>{t('drop_pa')}</div>
              <Input
                id='drop-pa-id'
                type='switch'
                checked={dropPA}
                disabled={!dropPA}
                className='me-2 form-check-right'
                onClick={(e) => {
                  setDropPA(e.target.checked)
                  setContractError('')
                  setContractStatus('')
                }}
              />
            </FormGroup>
            <FormGroup
              switch
              className='d-flex flex-row align-items-center gap-2 form-check-right'
            >
              <div className='mt-1'>{t('drop_insurance')}</div>
              <Input
                id='drop-insurance-id'
                type='switch'
                checked={dropInsurance}
                disabled={!dropInsurance}
                className='me-2 form-check-right'
                onClick={(e) => {
                  setDropInsurance(e.target.checked)
                  setContractError('')
                  setContractStatus('')
                }}
              />
            </FormGroup>
            <FormGroup
              switch
              className='d-flex flex-row align-items-center gap-2 form-check-right'
            >
              <div className='mt-1'>{t('drop_juridical')}</div>
              <Input
                id='drop-juridical-id'
                type='switch'
                checked={dropJuridical}
                disabled={!dropJuridical}
                className='me-2 form-check-right'
                onClick={(e) => {
                  setDropJuridical(e.target.checked)
                  setContractError('')
                  setContractStatus('')
                }}
              />
            </FormGroup>
            <FormGroup
              switch
              className='d-flex flex-row align-items-center gap-2 form-check-right'
            >
              <div className='mt-1'>{t('drop_telemedicine')}</div>
              <Input
                id='drop-telemedicine-id'
                type='switch'
                checked={dropTelemedicine}
                disabled={!dropTelemedicine}
                className='me-2 form-check-right'
                onClick={(e) => {
                  setDropTelemedicine(e.target.checked)
                  setContractError('')
                  setContractStatus('')
                }}
              />
            </FormGroup>
            <ConfirmButton
              size='sm'
              color='secondary'
              block={false}
              disabled={contractLoading}
              onConfirm={saveContract}
            >
              {t('Save')}
            </ConfirmButton>
            {contractLoading && (
              <small className='text-warning'>{t('loading')}</small>
            )}
            {contractStatus && (
              <small className='text-success'>{contractStatus}</small>
            )}
            {contractError && (
              <small className='text-danger'>{contractError}</small>
            )}
          </div>
        </div>

        {sessionStorage.getItem('goPageSet') === 'debts' ? (
          <>
            <div className='mt-5'>
              <h5 className='text-bold'>{t('Working with overdue debts')}</h5>
              {entity.stage_name ? (
                <div className='d-flex gap-2 mb-2 mt-2'>
                  <div
                    style={{
                      display: 'flex',
                      border: '1px solid #405189',
                      borderRadius: '10px',
                      padding: '3px 10px',
                      alignItems: 'center'
                    }}
                  >
                    {entity.stage_name}
                  </div>
                </div>
              ) : null}
              <div className='d-flex gap-4 flex-wrap'>
                <div>
                  <LoanTable
                    entity={entity}
                    fieldsDescription={fieldsDescriptionOverdueDebts(t)}
                    style={{ minWidth: '450px' }}
                  />
                  <LoanTable
                    entity={entity}
                    fieldsDescription={fieldsDescriptionOverdueDebtsSale(t)}
                  />
                </div>
                <div>
                  <LoanTable
                    entity={{ ...entity, ...ext_details }}
                    fieldsDescription={fieldsDescriptionOverdueDebtsExtension(
                      t
                    )}
                  />
                  <LoanTable
                    entity={entity}
                    fieldsDescription={fieldsDescriptionOverdueDebtsFreezing(t)}
                  />
                </div>
                <LoanTable
                  entity={entity}
                  fieldsDescription={fieldsDescriptionOverdueDebtsPromotion(t)}
                  style={{ minWidth: '250px' }}
                />
              </div>
            </div>
            <div className='mt-5 d-flex gap-4 flex-wrap'>
              <LoanTable
                title='Основные параметры займа'
                entity={{ ...entity, ...externalServicesAmount }}
                fieldsDescription={fieldsDescriptionMainLoans(t)}
              />
              <LoanTable
                title={`Задолженность на ${timeClient}`}
                entity={entity}
                fieldsDescription={fieldsDescriptionDebt(t)}
              />
              <LoanTable
                title={`Погашения на ${timeClient}`}
                entity={entity}
                fieldsDescription={fieldsDescriptionRepayments(t)}
              />
            </div>
          </>
        ) : (
          <>
            <div className='mt-3 d-flex gap-4 flex-wrap'>
              <LoanTable
                title='Основные параметры займа'
                entity={{ ...entity, ...externalServicesAmount }}
                fieldsDescription={fieldsDescriptionMainLoans(t)}
              />
              <LoanTable
                title={`Задолженность на ${timeClient}`}
                entity={entity}
                style={{ minWidth: '450px' }}
                fieldsDescription={fieldsDescriptionDebt(t)}
              />
              <LoanTable
                title={`Погашения на ${timeClient}`}
                entity={entity}
                fieldsDescription={fieldsDescriptionRepayments(t)}
              />
            </div>
            <div className='mt-5'>
              <h5 className='text-bold'>{t('Working with overdue debts')}</h5>
              {entity.stage_name ? (
                <div className='d-flex gap-2 mb-2 mt-2'>
                  <div
                    style={{
                      display: 'flex',
                      border: '1px solid #405189',
                      borderRadius: '10px',
                      padding: '3px 10px',
                      alignItems: 'center'
                    }}
                  >
                    {entity.stage_name}
                  </div>
                </div>
              ) : null}
              <div className='d-flex gap-4 flex-wrap'>
                <div>
                  <LoanTable
                    entity={entity}
                    fieldsDescription={fieldsDescriptionOverdueDebts(t)}
                    style={{ minWidth: '450px' }}
                  />
                  <LoanTable
                    entity={entity}
                    fieldsDescription={fieldsDescriptionOverdueDebtsSale(t)}
                  />
                </div>
                <div>
                  <LoanTable
                    entity={{ ...entity, ...ext_details }}
                    fieldsDescription={fieldsDescriptionOverdueDebtsExtension(
                      t
                    )}
                  />
                  <LoanTable
                    entity={entity}
                    fieldsDescription={fieldsDescriptionOverdueDebtsFreezing(t)}
                  />
                </div>
                <LoanTable
                  entity={entity}
                  fieldsDescription={fieldsDescriptionOverdueDebtsPromotion(t)}
                  style={{ minWidth: '250px' }}
                />
              </div>
            </div>
          </>
        )}
      </Col>
    </Row>
  )
}

export default EntityContent
