// Данные займа
export const fieldsDescriptionDetailsOne = (t: any) => ({
  product_code: {
    label: t('Product code'),
    isStrong: true
  },
  status: {
    label: t('Status'),
    isStrong: true
  },
  loan_issue_card: {
    label: t('Loan issue card'),
    isStrong: true
  },
  short_name: {
    label: t('Short name'),
    isStrong: true,
    isCopy: true
  },
  signed_at: {
    label: t('Signed at'),
    isStrong: true,
    isDateTime: true
  }
})

export const fieldsDescriptionDetailsTwo = (t: any) => ({
  overdue_status: {
    label: t('Оverdue status'),
    isStrong: true,
    mappingValue: (value: boolean | null) => (value ? t('Yes') : t('No'))
  },
  on_holiday: {
    label: t('On holiday'),
    isStrong: true,
    mappingValue: (value: boolean | null) => (value ? t('Yes') : t('No'))
  },
  org_collect: {
    label: t('Org collect'),
    isStrong: true,
    mappingValue: (value: boolean | null) => (value ? t('Yes') : t('No'))
  }
})

export const fieldsDescriptionDetailsThree = (t: any) => ({
  org_origin: {
    label: t('Org origin'),
    isStrong: true
  },
  org_owner: {
    label: t('Org owner'),
    isStrong: true
  },
  collector_fio: {
    label: t('Collector fio'),
    isStrong: true
  }
})

// Основные параметры займа
export const fieldsDescriptionMainLoans = (t: any) => ({
  amount_accrued: {
    label: t('Amount accrued'),
    isAmount: true,
    isStrong: true,
    dashEmpty: true,
    isDefaultOpen: true,
    isAccordion: {
      amount_principal_accrued: {
        label: t('issued on the card'),
        isAmount: true,
        dashEmpty: true
      },
      amount_services_debt: {
        label: `${t('amount services debt')}:`,
        isAmount: true,
        dashEmpty: true
      },
      amount_insurance_accrued: {
        label: t('amount services insurance debt'),
        isItem: true,
        isAmount: true,
        dashEmpty: true
      },
      amount_juridical_accrued: {
        label: t('amount services juridical debt'),
        isItem: true,
        isAmount: true,
        dashEmpty: true
      },
      amount_telemedicine_accrued: {
        label: t('amount services telemedicine debt'),
        isItem: true,
        isAmount: true,
        dashEmpty: true
      }
    }
  },
  interest: {
    label: `${t('Interest')}, %`,
    isStrong: true,
    dashEmpty: true
  },
  psk: {
    label: `${t('PSK')}, %`,
    isStrong: true,
    dashEmpty: true
  },
  issue_date: {
    label: t('Issue date'),
    isDate: true,
    isStrong: true,
    dashEmpty: true
  },
  repayment_date: {
    label: t('Repayment date'),
    isDate: true,
    isStrong: true,
    dashEmpty: true
  },
  closing_date: {
    label: t('Closing date'),
    isDate: true,
    isStrong: true,
    dashEmpty: true
  },
  term: {
    label: t('Term'),
    isStrong: true
  }
})

// Задолженность
export const fieldsDescriptionDebt = (t: any) => ({
  amount_debt: {
    label: t('Amount debt'),
    isAmount: true,
    isStrong: true,
    isDefaultOpen: true,
    isAccordion: {
      amount_interest_debt: {
        label: t('amount interest debt'),
        isAmount: true,
        isItem: true,
        dashEmpty: true
      },
      amount_principal_debt: {
        label: t('amount principal debt'),
        isAmount: true,
        isItem: true,
        dashEmpty: true
      },
      amount_overdue_interest_debt: {
        label: t('amount overdue interest'),
        isAmount: true,
        isItem: true,
        dashEmpty: true
      },
      amount_penalty_debt: {
        label: t('amount penalty'),
        isAmount: true,
        isItem: true,
        dashEmpty: true
      }
    }
  },
  amount_closing_overdue_debt: {
    label: t('Amount closing overdue debt'),
    isAmount: true,
    isStrong: true,
    isDefaultOpen: true,
    isAccordion: {
      amount_overdue_debt: {
        label: t('amount overdue debt'),
        isAmount: true,
        isItem: true,
        dashEmpty: true
      },
      amount_overdue_interest_debt: {
        label: t('amount overdue interest'),
        isAmount: true,
        isItem: true,
        dashEmpty: true
      }
    }
  },
  next_schedule_payment_date: {
    label: t('Next schedule payment date'),
    isStrong: true,
    isDate: true
  },
  amount_next_payment: {
    label: t('Amount next payment'),
    isStrong: true,
    isAmount: true
  }
})

// Погашения
export const fieldsDescriptionRepayments = (t: any) => ({
  amount_paid: {
    label: t('Amount paid'),
    isAmount: true,
    isStrong: true,
    dashEmpty: true,
    isDefaultOpen: true,
    isAccordion: {
      amount_interest_paid: {
        label: t('amount interest'),
        isAmount: true,
        isItem: true,
        dashEmpty: true
      },
      amount_principal_paid: {
        label: t('amount principal'),
        isAmount: true,
        isItem: true,
        dashEmpty: true
      },
      amount_overdue_interest_paid: {
        label: t('amount overdue interest'),
        isAmount: true,
        isItem: true,
        dashEmpty: true
      },
      amount_penalty_paid: {
        label: t('amount penalty'),
        isAmount: true,
        isItem: true,
        dashEmpty: true
      }
    }
  },
  amount_prepayment: {
    label: t('Amount prepayment'),
    isAmount: true,
    isStrong: true,
    dashEmpty: true
  },
  amount_overpayment: {
    label: t('Amount over payment'),
    isAmount: true,
    isStrong: true,
    dashEmpty: true
  },
  amount_writeoff: {
    label: t('Amount writeoff'),
    isAmount: true,
    isStrong: true,
    dashEmpty: true
  }
})

// Просроченная задолженность
export const fieldsDescriptionOverdueDebts = (t: any) => ({
  overdue_debt: {
    label: t('Overdue debt'),
    isStrong: true,
    isEmpty: true
  },
  overdue_payment_periods: {
    label: t('Overdue payment periods')
  },
  dpd: {
    label: t('Dpd')
  },
  amount_overdue_debt: {
    label: t('Amount overdue debt'),
    isAmount: true
  },
  extension_interest_rate: {
    label: t('Extension interest rate'),
    isAmount: true
  }
})

// Продажа третьим лицам
export const fieldsDescriptionOverdueDebtsSale = (t: any) => ({
  sale_third_parties: {
    label: t('Sale third parties'),
    isStrong: true,
    isEmpty: true
  },
  company_short_name: {
    label: t('Company short name')
  },
  sale_date: {
    label: t('Sale date'),
    isDate: true
  }
})

// Продление
export const fieldsDescriptionOverdueDebtsExtension = (t: any) => ({
  extension: {
    label: t('Extension'),
    isStrong: true,
    isEmpty: true
  },
  extension_available: {
    label: t('Extension available'),
    mappingValue: (value: boolean | null) => (value ? t('Yes') : t('No'))
  },
  amount_for_extension: {
    label: t('Amount for extension'),
    isAmount: true
  },
  extension_type: {
    label: t('Extension type')
  },
  extension_type_light_is_activated: {
    label: t('Extension type light is activated'),
    mappingValue: (value: boolean | null) => (value ? t('Yes') : t('No'))
  },
  prolongation_count: {
    label: t('Prolongation count')
  }
})

// Заморозка
export const fieldsDescriptionOverdueDebtsFreezing = (t: any) => ({
  freezing: {
    label: t('Freezing'),
    isStrong: true,
    isEmpty: true
  },
  interest_freezing: {
    label: t('Interest freezing'),
    mappingValue: (value: boolean | null) => (value ? t('Yes') : t('No'))
  },
  fines_freezing: {
    label: t('Fines freezing'),
    mappingValue: (value: boolean | null) => (value ? t('Yes') : t('No'))
  },
  interest_frozen_end_date: {
    label: t('Interest frozen end date'),
    isDate: true
  },
  penalties_frozen_end_date: {
    label: t('Penalties frozen end date'),
    isDate: true
  }
})

// Акция
export const fieldsDescriptionOverdueDebtsPromotion = (t: any) => ({
  promotion: {
    label: t('Promotion'),
    isStrong: true,
    isEmpty: true
  },
  activation_date: {
    label: t('Activation date'),
    isDate: true
  },
  expiration_date: {
    label: t('Expiration date'),
    isDate: true
  },
  amount_to_return: {
    label: t('Amount to return'),
    isAmount: true
  },
  main_body_writeoff: {
    label: t('Main body writeoff'),
    isAmount: true
  },
  interest_writeoff: {
    label: t('Interest writeoff'),
    isAmount: true
  }
})
